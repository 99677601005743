import React, { Component } from 'react';

export const colors = [
  '#00A1E0', // Relationship Management
  '#215CA0', // Contact Center & Case Management
  '#4C2248',
  '#BE8919', // Digital Commerce
  '#215CA0', // Application Platform
  '#D3451D', // Analytics & Visualization
  '#00857D',
  '#812244', // Productivity & Employee Experience
  '#E67300', // learn and reskilling
  '#416437', // Government Compliance & Security
  '#211746', // Communities & Self- Service 
  '#812244', 
  '#E67300',
  '#416437',
  '#032E61' //  Mission Success
];

class Wheel extends Component {
  render() {
    let { capabilities } = this.props;

    let wheelItems = capabilities.map(c => {
      return {
        name: c.title[0].text,
        icon: c.icon.url,
        iconActive: c.active_icon.url,
        color: c.color,
        rank: c.rank,
        wheel_order: c.wheel_order
      };
    });
    
    wheelItems = wheelItems.sort(
      (a, b) =>{
        if (a.wheel_order < b.wheel_order) return -1;
        if (a.wheel_order > b.wheel_order) return 1;
        return 0;
      });

    wheelItems = wheelItems.slice(0, 12);

    /*
      Icon Order
      1. Advanced Analytics
      2. Integration
      3. FedRamp Government Cloud
      4. Communities & Self Service
      5. Learning & Engagement
      6. Collaboration
      7. Relationship Management
      8. Contact Center & Case Management
      9. Marketing & Outreach
      10. Digital Commerce
      11. Build Custom Experiences
      12. Application Development Platform
    */
    /*
      Icon Order 2020
      1. Relationship Management 
      2. Contact Center & Case Management
      3. Marketing Communications & Outreach
      4. Digital Commerce
      5. Application Platform
      6. Analytics & Visualization
      7. Integration & APIs
      8. Productivity & Employee Experience
      9. Learning & Re-skilling
      10. Government Compliance & Security
      11. Communities & Self-Service 
      12. Mission Success
    */
    return (
      <div className="wheel-container">
        <div className="wheel-wrapper">
          <div className="wheel_body">
            {wheelItems.map((capability, index) => {
              return (
                <div className="wheel_item" key={`wheel-${index}`}>
                  <div className="wheel_item-content">
                    <img src={capability.icon} alt={capability.name} />
                    <div className="icon-tool-tip">
                      <span className="icon-tool-tip-text">{capability.name}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="wheel_content">
            {wheelItems.map((capability, index) => {
              return (
                <div key={`wheel-selector-${index}`}>
                  <div className={`wheel-selector wheel-selector-${index}`}>
                    <svg
                      width="132px"
                      height="174px"
                      viewBox="0 0 132 174"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      className={`wheel-selector-indicator wheel-selector-indicator-${index}`}
                    >
                      <defs>
                        <polygon id="path-1" points="21 0.785 155 0.785 156 180 21 180" />
                      </defs>
                      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="D---Homepage" transform="translate(-892.000000, -35.000000)">
                          <g id="Hero-/-Wheel">
                            <g id="Background-IMG" transform="translate(-816.000000, 30.000000)">
                              <g id="wheel" transform="translate(1475.000000, 0.000000)">
                                <g id="active" transform="translate(210.000000, 0.215000)">
                                  <mask id="mask-2" fill="white">
                                    <use xlinkHref="#path-1" />
                                  </mask>
                                  <g id="Clip-2" />
                                  <path
                                    d="M155,14.1189144 C110.740448,2.00025153 67.2811598,1.9614656 23,13.9967387 L62.1840715,159.737768 L88.7263038,178 L116.123702,160.328284"
                                    id="Fill-1"
                                    fill={capability.color}
                                    mask="url(#mask-2)"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <img
                      className={`wheel-selector-icon wheel-selector-icon-${index}`}
                      src={capability.iconActive}
                      alt={capability.name}
                    />
                  </div>
                  <span className={`wheel-title wheel-title-${index}`}>{capability.name}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Wheel;
