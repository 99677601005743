import { graphql } from 'gatsby';

import { PriorityFragment } from './priorityFragment';

export const PrioritiesFragment = graphql`
  fragment PrioritiesFragment on PRISMIC_Tour {
    priorities {
      priority {
        ... on PRISMIC_Priority {
          ...PriorityFragment
        }
      }
    }
  }
`;
