import { graphql } from 'gatsby';

export const PriorityFragment = graphql`
  fragment PriorityFragment on PRISMIC_Priority {
    _meta {
      id
      lang
    }
    title
  }
`;
