import { graphql } from 'gatsby';

export const PrismicMetaFragment = graphql`
  fragment PrismicMetaFragment on PRISMIC__Document {
    _meta {
      uid
      id
      type
      tags
      lang
      alternateLanguages {
        id
      }
      lastPublicationDate
      firstPublicationDate
    }
  }
`;
