import React, { Component } from 'react';
import TourCapabilities from '../components/TourCapabilities';
import moment from 'moment';
import { Link } from 'gatsby';
import { linkResolver } from '../utils/linkResolver';
import AppContext from '../context/AppContext';
import addToPlaylistIcon from '../_images/global/plus-circle-light.svg';
import checkedPlaylistIcon from '../_images/global/check-circle-light.svg';

class ToursGrid extends Component {
  isInPlaylist = id => {
    let { playlist } = this.props;
    return playlist.filter(list => list._meta.id === id).length > 0;
  };

  renderTourGrid = () => {
    let { addToPlaylist, removeFromPlaylist, tours, labels, customPage, backToToursLink, foreignLanguage } = this.props;

    return tours.map((tour, index) => {
      let createdDate = tour._meta.firstPublicationDate;
      let thirtyDays = moment(createdDate).add(30, 'days')._d;
      let todayForNewOrOld = new Date();

      let isTourLessThan30daysold = moment(thirtyDays).isAfter(todayForNewOrOld);

      if (tour.password === null && !tour.private_tour && !customPage) {
        return (
          <div className="tour-module-wrapper" key={tour._meta.id}>
            <AppContext.Consumer>
              {app => {
                return (
                  <div className="tour-module-component">
                    <Link
                      to={linkResolver(tour._meta)}
                      className={`tour-new-text-wrapper ${
                        isTourLessThan30daysold ? 'has-new-badge' : ''
                      } has-new-badge`}
                    >
                      <div>
                        {isTourLessThan30daysold && (
                          <div className="new-tour " onClick={() => app.goToTour(tour._meta)}>
                            <span className="badge">{labels.new}</span>
                          </div>
                        )}
                      </div>
                      <div className={`tour-module--text ${foreignLanguage ? 'foreign-language' : ''}`}>
                        {tour.tour_title[0].text}
                      </div>
                    </Link>
                    <div className="tour-capabilities-container" onClick={() => app.goToTour(tour._meta)}>
                      <div className="tour-icons-container">
                        <TourCapabilities capabilities={tour.capabilities} />
                      </div>
                    </div>
                    {app.showAddToPlaylistButtons && (
                      <div
                        className={`add-to-playlist-btn ${this.isInPlaylist(tour._meta.id) ? 'checked' : ''}`}
                        onClick={() =>
                          !this.isInPlaylist(tour._meta.id)
                            ? addToPlaylist(tours, tour._meta.id)
                            : removeFromPlaylist(tours, tour._meta.id)
                        }
                      >
                        <span className="add-to-playlist-btn--plus-sign">
                          <img
                            src={`${this.isInPlaylist(tour._meta.id) ? checkedPlaylistIcon : addToPlaylistIcon}`}
                            alt=""
                          />
                        </span>
                        <span
                          className={`add-to-playlist-btn--playlist-text ${
                            this.isInPlaylist(tour._meta.id) ? 'added-to-list' : ''
                          }`}
                        >
                          {this.isInPlaylist(tour._meta.id) ? 'Added To Playlist' : 'Add To Playlist'}
                        </span>
                      </div>
                    )}
                  </div>
                );
              }}
            </AppContext.Consumer>
          </div>
        );
      }
      if (customPage) {
        return (
          <div className="tour-module-wrapper" key={tour._meta.id}>
            <AppContext.Consumer>
              {app => {
                return (
                  <div className="tour-module-component">
                    <Link
                      to={linkResolver(tour._meta)}
                      className={`tour-new-text-wrapper ${isTourLessThan30daysold ? 'has-new-badge' : ''} `}
                    >
                      <div>
                        {isTourLessThan30daysold && (
                          <div className="new-tour " onClick={() => app.goToTour(tour._meta)}>
                            <span className="badge">{labels.new}</span>
                          </div>
                        )}
                      </div>
                      <div className={`tour-module--text ${foreignLanguage ? 'foreign-language' : ''}`}>
                        {tour.tour_title[0].text}
                      </div>
                    </Link>
                    <div className="tour-capabilities-container" onClick={() => app.goToTour(tour._meta)}>
                      <div className="tour-icons-container">
                        <TourCapabilities capabilities={tour.capabilities} />
                      </div>
                    </div>
                    {app.showAddToPlaylistButtons && (
                      <div
                        className={`add-to-playlist-btn ${this.isInPlaylist(tour._meta.id) ? 'checked' : ''}`}
                        onClick={() =>
                          !this.isInPlaylist(tour._meta.id)
                            ? addToPlaylist(tours, tour._meta.id)
                            : removeFromPlaylist(tours, tour._meta.id)
                        }
                      >
                        <span className="add-to-playlist-btn--plus-sign">
                          <img
                            src={`${this.isInPlaylist(tour._meta.id) ? checkedPlaylistIcon : addToPlaylistIcon}`}
                            alt=""
                          />
                        </span>
                      </div>
                    )}
                  </div>
                );
              }}
            </AppContext.Consumer>
          </div>
        );
      }
    });
  };

  render() {
    return this.renderTourGrid();
  }
}

export default ToursGrid;
