import React, { Component } from 'react';
import Spinner from '../_images/global/spinner-1s-200px.svg';

class Loading extends Component {
  render() {
    return (
      <div className="loading-spinner">
        <img src={Spinner} alt="" />
      </div>
    );
  }
}

export default Loading;
