import React, { Component } from 'react';
import { Accordion, AccordionItem } from 'react-light-accordion';
import { RichText } from 'prismic-reactjs';
import AppContext from '../context/AppContext';
import DataContext from '../context/DataContext';

class TourAccordion extends Component {
  getPriorityFilterCount(app, data) {
    var priorityArrayLength = app.activeFilters.filter(function(e) {
      let priorityArray = [];
      data.priorities.map((priority, index) => {
        if (priority._meta.id.indexOf(e) > -1) {
          return priorityArray.push(e);
        } else {
          return false;
        }
      });
      return priorityArray.length;
    });

    if (priorityArrayLength.length > 0) {
      return '(' + priorityArrayLength.length + ')';
    } else {
      return '';
    }
  }

  getCapabilityCount(app, data) {
    const capabilityArrayLength = app.activeFilters.filter(function(e) {
      let capabilityArray = [];
      data.capabilities.map((capability, index) => {
        if (capability._meta.id.indexOf(e) > -1) {
          capabilityArray.push(e);
        }
        return false;
      });
      return capabilityArray.length;
    });

    if (capabilityArrayLength.length > 0) {
      return '(' + capabilityArrayLength.length + ')';
    } else {
      return '';
    }
  }

  getOrgTypeCount(app, data) {
    const orgTypeArrayLength = app.activeFilters.filter(function(e) {
      let orgTypeArray = [];
      data.organizationTypes.map((orgType, index) => {
        if (orgType._meta.id.indexOf(e) > -1) {
          return orgTypeArray.push(e);
        } else {
          return false;
        }
      });
      return orgTypeArray.length;
    });

    if (orgTypeArrayLength.length > 0) {
      return '(' + orgTypeArrayLength.length + ')';
    } else {
      return '';
    }
  }

  componentDidMount() {
    // open priority category by default
    let desktopPriority = document.querySelector(
      '.filter-accordion-desktop .accordion-container .accordion > .accordion-item'
    );
    let mobilePriority = document.querySelector(
      '.filter-accordion-mobile .accordion-container .accordion > .accordion-item'
    );
    desktopPriority.classList.add('active');
    mobilePriority.classList.add('active');
  }

  render() {
    return (
      <DataContext.Consumer>
        {data => {

          return (
            <AppContext.Consumer>
              {app => {

                // per specs filters need to be in alphabetical order
                // to do this we use the localeCompare function and set the language we are using
                // language is determined by splitting app.lang and getting the first array (0) from the split
                // this should work with upcoming languages
                let languageForLocaleSort = data.lang.split('-');
                languageForLocaleSort = languageForLocaleSort[0];
                return (
                  <Accordion>
                    {/* <AccordionItem title={`Priorities ${this.getPriorityFilterCount(app, data)}`} key="priority">
                      {data.priorities.map(priority => {
                        if (data.language !== 'en-us') {
                          // sort by alpabetical order
                          data.priorities.sort((a, b) =>
                            a.title[0].text.localeCompare(b.title[0].text, `${languageForLocaleSort}`, {
                              ignorePunctuation: true
                            })
                          );
                        }
                        return (
                          <div key={priority._meta.id} className="tour-checkbox-container">
                            <input
                              key={`${priority._meta.id}-input`}
                              type="checkbox"
                              id={priority._meta.id}
                              className="tour-checkbox tour-checkbox-priority"
                              name={priority._meta.id}
                              value={priority._meta.id}
                              checked={app.activeFilters.includes(priority._meta.id)}
                              onChange={() => app.toggleFilter(priority._meta.id)}
                              disabled={data.filterCount(priority._meta.id) === 0}
                            />
                            <label
                              htmlFor={priority._meta.id}
                              className={data.filterCount(priority._meta.id) === 0 ? 'disabled' : ''}
                            >
                              {RichText.asText(priority.title)}
                              {data.filterCount(priority._meta.id) < 20
                                ? '(' + data.filterCount(priority._meta.id) + ')'
                                : ''}
                            </label>
                          </div>
                        );
                      })}
                    </AccordionItem> */}

                    {/* <AccordionItem title={`Capabilities ${this.getCapabilityCount(app, data)}`} key="capabalities">
                      {data.capabilities.map(capability => {
                        // sort capabilities in alphabetical order
                        data.capabilities.sort((a, b) => {
                          if (a.title[0].text < b.title[0].text) return -1;
                          if (a.title[0].text > b.title[0].text) return 1;
                          return 0;
                        });
                        if (data.lang !== 'en-us') {
                          data.capabilities.sort((a, b) =>
                            a.title[0].text.localeCompare(b.title[0].text, `${languageForLocaleSort}`, {
                              ignorePunctuation: true
                            })
                          );
                        }

                        return (
                          <div
                            key={`${capability._meta.id}${Math.floor(Math.random() * 20)}`}
                            className="tour-checkbox-container tour-checkboxes-capabilities"
                          > */}
                            
                            {/* currently Learning & Engagement is rendering twice causing a duplicate key adding a random number solves this issue */}
                            {/* <input
                              type="checkbox"
                              id={capability._meta.id}
                              className="tour-checkbox tour-checkbox-capability"
                              name={capability.title[0].text}
                              value={capability._meta.id}
                              checked={app.activeFilters.includes(capability._meta.id)}
                              onChange={() => app.toggleFilter(capability._meta.id)}
                              disabled={data.filterCount(capability._meta.id) === 0}
                            />
                            <label
                              htmlFor={capability._meta.id}
                              className={data.filterCount(capability._meta.id) === 0 ? 'disabled' : ''}
                            >
                              {RichText.asText(capability.title)}
                              {data.filterCount(capability._meta.id) < 20
                                ? '(' + data.filterCount(capability._meta.id) + ')'
                                : ''}
                            </label>
                          </div>
                        );
                      })}
                    </AccordionItem> */}

                    <AccordionItem title={`Organization ${this.getOrgTypeCount(app, data)}`} Types key="org-types">
                      {data.organizationTypes.map((organiationType, index) => {
                        // sort org types in alphabetical order

                        data.organizationTypes.sort((a, b) => {
                          if (a.title[0].text < b.title[0].text) return -1;
                          if (a.title[0].text > b.title[0].text) return 1;
                          return 0;
                        });
                        if (app.lang !== 'en-us') {
                          data.organizationTypes.sort((a, b) =>
                            a.title[0].text.localeCompare(b.title[0].text, `${languageForLocaleSort}`, {
                              ignorePunctuation: true
                            })
                          );
                        }
                        return (
                          <div
                            key={`${index}-checkbox`}
                            className="tour-checkbox-container tour-checkboxes-organization-types"
                          >
                            <input
                              type="checkbox"
                              id={organiationType._meta.id}
                              className="tour-checkbox"
                              name={organiationType.title}
                              value={organiationType._meta.id}
                              checked={app.activeFilters.includes(organiationType._meta.id)}
                              onChange={() => app.toggleFilter(organiationType._meta.id)}
                              disabled={data.filterCount(organiationType._meta.id) === 0}
                            />
                            <label
                              htmlFor={organiationType._meta.id}
                              className={data.filterCount(organiationType._meta.id) === 0 ? 'disabled' : ''}
                            >
                              {RichText.asText(organiationType.title)}
                              {' (' + data.filterCount(organiationType._meta.id) + ')'}
                              {/* {data.filterCount(organiationType._meta.id) < 20
                                ? '(' + data.filterCount(organiationType._meta.id) + ')'
                                : ''} */}
                            </label>
                          </div>
                        );
                      })}
                    </AccordionItem>
                  </Accordion>
                );
              }}
            </AppContext.Consumer>
          );
        }}
      </DataContext.Consumer>
    );
  }
}

TourAccordion.fragments = [];

export default TourAccordion;
