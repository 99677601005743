import React, { Component } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { graphql /*Link */ } from 'gatsby';
import ToursGrid from '../components/ToursGrid';
import TourPlaylistBanner from '../components/TourPlaylistBanner';
import AppContext from '../context/AppContext';
import PageHeader from '../components/PageHeader';
import Accordion from '../components/Accordion';
import format from 'string-format-obj';
import DataContext from '../context/DataContext';
import { DataProvider } from '../context/DataProvider';
import { OrganizationTypeFragment } from '../fragments/organizationTypeFragment';
import { CapabilityFragment } from '../fragments/capabilityFragment';
import { PriorityFragment } from '../fragments/priorityFragment';
import { TourFragment } from '../fragments/tourFragment';
import { TourSeoFragment } from '../fragments/tourSeoFragment';
import { PrismicMetaFragment } from '../fragments/prismicMetaFragment';
import { OrganizationTypesFragment } from '../fragments/organizationTypesFragment';
import { PrioritiesFragment } from '../fragments/prioritiesFragment';
import { CapabilitiesFragment } from '../fragments/capabilitiesFragment';
import TourTabs from '../components/TourTabs';
import Loading from '../components/Loading';

const getMoreTourQuery = variables => {
  return {
    variables: { ...variables },
    query: moreTourQuery, // (optional)
    fragments: [
      OrganizationTypeFragment,
      OrganizationTypesFragment,
      CapabilityFragment,
      CapabilitiesFragment,
      PriorityFragment,
      TourFragment,
      PrioritiesFragment,
      TourSeoFragment,
      PrismicMetaFragment
    ] // (optional)
  };
};

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tours: []
    };
  }

  loadTours = (variables, tours, prismic) => {
    return new Promise((resolve, reject) => {
      prismic
        .load(getMoreTourQuery(variables))
        .then(res => {
          let { hasNextPage, endCursor } = res.data.allTours.pageInfo;

          let newTours = res.data.allTours.edges.map(x => x.node);

          if (hasNextPage) {
            resolve(this.loadTours({ after: endCursor }, [...tours, ...newTours], prismic));
          } else {
            resolve([...tours, ...newTours]);
          }
        })
        .catch(e => {
          reject(e);
        });
    });
  };

  componentDidMount() {
    // we need to check if there are more items after the initial 20
    // currently we know that there could be more tours so we need to run a logic check with the data from prisimc
    // and if it has another page to get the data from after the endCurser

    let { prismic } = this.props;

    this.loadTours({ after: undefined }, [], prismic)
      .then(tours => {
        this.setState({
          tours
        });
      })
      .catch(message => {
        console.log('error message', message);
      });
  }

  render() {
    const homepage = this.props.data.prismic.allHomepages.edges[0].node;
    let globalNavCTA = this.props.data.prismic.allGlobal_navigations.edges[0].node.global_cta;
    let priorities = this.props.data.prismic.allPriorities.edges.map(x => x.node);
    let capabilities = this.props.data.prismic.allCapabilities.edges.map(x => x.node);
    let organizationTypes = this.props.data.prismic.allOrganizationTypes.edges.map(x => x.node);
    let languages = this.props.data.prismic.allLanguages.edges.map(x => x.node);

    let { tours } = this.state;

    let pencilBanner =
      this.props.data.prismic.allGlobal_navigations.edges[0].node.pencil_banner &&
      this.props.data.prismic.allGlobal_navigations.edges[0].node.pencil_banner.length > 0;

    return (
      <AppContext.Consumer>
        {app => {
          let isForeignLanguage = !this.props.pathContext.lang.includes('en');
          return (
            <DataProvider
              lang={this.props.pageContext.lang}
              languages={languages}
              tours={tours}
              activeFilters={app.activeFilters}
              playlist={app.playlist}
              priorities={priorities}
              capabilities={capabilities}
              organizationTypes={organizationTypes}
              setSortMethod={app.setSortMethod}
              sortMethod={app.sortMethod}
            >
              <DataContext.Consumer>
                {data => {
                  return (
                    <Layout location={'home'} lang={this.props.pageContext.lang} languages={data.languages}>
                      <SEO
                        title={homepage.meta_title}
                        description={homepage.meta_description}
                        lang={homepage.lang}
                        keywords={[]}
                      />
                      <PageHeader
                        title={homepage.title}
                        content={homepage.introduction}
                        capabilities={capabilities}
                        globalNavCTA={globalNavCTA}
                        pencilBanner={pencilBanner}
                      />

                      <div className="tour-tabs" style={{ width: '100%' }}>
                        <TourTabs tabList={organizationTypes}></TourTabs>
                      </div>
                      <div className="container-fluid main-container">
                        <div
                          className={`filter-overlay ${app.showFilters || app.showSort ? '' : 'hide'} `}
                          onClick={() => app.closeOverlay()}
                        />
                        <div className="container">
                          <div className="row">
                            {/* mobile button */}
                            <div className="filter-sort-button-mobile">
                              <div className="filter-container">
                                <button
                                  onClick={() => app.toggleMobileSortAndFilter()}
                                  className={`tour-filter-button mobile ${app.showFilters ? 'active' : ''}`}
                                >
                                  Filter By Organization Type
                                  <span>{app.activeFilters.length > 0 ? `(${app.activeFilters.length})` : ''}</span>
                                </button>
                              </div>
                            </div>
                            {/* mobile button */}
                            {/* <div className="filter-sort-button-desktop">
                              <div className="filter-container">
                                <button
                                  onClick={() => app.toggleShowFilters()}
                                  className={`tour-filter-button ${app.showFilters ? 'active' : ''}`}
                                >
                                  {homepage.filter}
                                  <span>{app.activeFilters.length > 0 ? `(${app.activeFilters.length})` : ''}</span>
                                </button>
                              </div>
                              <div className="sort-button-container">
                                <button
                                  onClick={() => app.toggleShowSort()}
                                  className={`tour-sort-button ${app.showSort ? 'active' : ''}`}
                                >
                                  {format(homepage.sort_by, { method: homepage[app.sortMethod] })}
                                </button>
                              </div>
                            </div> */}
                          </div>
                          {/* mobile sort & filter */}
                          <div className="row">
                            <div className="filter-accordion-mobile-wrapper">
                              <div className="filter-accordion-mobile">
                                {/* {app.showSort && (
                                  <div className="sort-container">
                                    <div className="sorted-tours-container">
                                      <div className="sorted-tours-wrapper">
                                        <li className="sort-item sort-item-header">Sort</li>
                                        {app.sortOptions.map((option, index) => {
                                          return (
                                            <li
                                              className={`sort-item ${option === app.sortMethod ? 'active' : ''}`}
                                              key={index}
                                              onClick={() => app.setSortMethod(option)}
                                            >
                                              <span className="sort-check-flex">
                                                <span>{homepage[option]}</span>
                                                <span
                                                  className={`sort-check ${option === app.sortMethod ? '' : 'hidden'}`}
                                                >
                                                  &#x2713;
                                                </span>
                                              </span>
                                            </li>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                )} */}
                                {app.showFilters && (
                                  <div className="accordion-container">
                                    <div className="filter-counter">
                                      <span>{homepage.filter}</span>&nbsp;
                                      <span>
                                        {app.activeFilters.length > 0 ? '(' + app.activeFilters.length + ')' : ''}
                                      </span>
                                    </div>
                                    <button onClick={() => app.clearFilters()} className="filter-reset-button">
                                      {homepage.reset_all_filters}
                                    </button>
                                    <Accordion />
                                  </div>
                                )}
                              </div>
                              <div className="filter-accordion-desktop">
                                {app.showFilters && (
                                  <div className="filter-container-buttons">
                                    <div className="accordion-container">
                                      <button onClick={() => app.clearFilters()} className="filter-reset-button">
                                        {homepage.reset_all_filters}
                                      </button>
                                      <Accordion />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* desktop sort & filter */}
                          <div className="row">
                            {app.showSort && (
                              <div className="sort-container-desktop">
                                <div className="sorted-tours-container">
                                  <div className="sorted-tours-wrapper">
                                    {app.sortOptions.map((option, index) => {
                                      return (
                                        <li className="sort-item" key={index} onClick={() => app.setSortMethod(option)}>
                                          <span className="sort-check-flex">
                                            <span>{homepage[option]}</span>
                                            <span className={`sort-check ${option === app.sortMethod ? '' : 'hidden'}`}>
                                              &#x2713;
                                            </span>
                                          </span>
                                        </li>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* <br /> */}
                          <div className="row">
                            {data.tours.length < 1 && <Loading />}
                            <div className="tour-container">
                              <ToursGrid
                                tours={app.activeFilters.length ? data.filteredTours : data.tours}
                                sortMethod={app.sortMethod}
                                addToPlaylist={app.addToPlaylist}
                                removeFromPlaylist={app.removeFromPlaylist}
                                playlist={app.playlist}
                                labels={{
                                  new: homepage.new,
                                  capabilities: homepage.capabilities,
                                  add_to_playlist: homepage.add_to_playlist,
                                  added_to_playlist: homepage.added_to_playlist
                                }}
                                foreignLanguage={isForeignLanguage}
                              />
                            </div>
                          </div>
                        </div>
                        <div className={`playlist-feature-container ${app.showAddToPlaylistButtons ? 'hide' : ''}`}>
                          <div className="playlist-button-wrapper">
                            Want to create your own playlist from selected tours?
                            <button className="show-playlist-button" onClick={() => app.activatePlaylistFeature()}>
                              Create A Playlist
                            </button>
                          </div>
                        </div>
                        {app.showAddToPlaylistButtons && (
                          <div className="tour-playlist-wrapper">
                            <TourPlaylistBanner
                              playlist={app.playlist}
                              setCurrentTourTitle={app.setCurrentTourTitle}
                              labels={homepage}
                            />
                          </div>
                        )}
                      </div>
                    </Layout>
                  );
                }}
              </DataContext.Consumer>
            </DataProvider>
          );
        }}
      </AppContext.Consumer>
    );
  }
}

const moreTourQuery = `
  query MoreTourQuery($lang: String!, $after: String) {
    prismic {
      allTours(lang: $lang, after: $after) {
        edges {
          node {
            ...TourFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const query = graphql`
  query HomePageQuery($lang: String!, $after: String) {
    prismic {
      allHomepages(lang: $lang) {
        edges {
          node {
            ...HomepageFragment
          }
        }
      }
      allLanguages(lang: $lang) {
        edges {
          node {
            ...LanguageFragment
          }
        }
      }
      allTours(lang: $lang, after: $after) {
        edges {
          node {
            ...TourFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      allPriorities: allPrioritys(lang: $lang) {
        edges {
          node {
            ...PriorityFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      allCapabilities: allCapabilitys(lang: $lang) {
        edges {
          node {
            ...CapabilityFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      allOrganizationTypes: allOrganization_types(lang: $lang) {
        edges {
          node {
            ...OrganizationTypeFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      allGlobal_navigations(lang: $lang) {
        edges {
          node {
            ...GlobalNavigationFragment
          }
        }
      }
    }
  }
`;

export default HomePage;
