import React from 'react';


export const defaultState = {
  tours: [],
  homepage: undefined,
  currentTourTitle: '',
  currentSlide: null,
  filteredTours: [],
  lang: 'en-us'
};

const DataContext = React.createContext(defaultState);

export default DataContext;
