import React, { Component } from 'react';
import Wheel from './Wheel';
import { RichText } from 'prismic-reactjs';
import hero from '../_images/global/Hero/hero-image.png';
import cityscapeEdge from '../_images/global/Hero/cityscape-1280.png';
import cityscapeFranceDesktop from '../_images/global/Hero/sf-skyline-2020.svg';
import cityscapeFranceMobile from '../_images/global/Hero/sf-skyline-2020-mobile.svg';
import Button from '../components/Button';
import AppContext from '../context/AppContext';

class PageHeader extends Component {
  state = {
    isEdge: false
  };

  componentDidMount() {
    // detect if edge browser
    if (navigator.userAgent.indexOf('Edge') >= 0) {
      this.setState({
        isEdge: true
      });
    }
  }
  render() {
    let { title, content, globalNavCTA, capabilities, pencilBanner, customPage } = this.props;
    let { isEdge } = this.state;

    const cloudStyle = {
      backgroundImage: 'url(' + hero + ')'
    };
    const cityscapeStyle = {
      backgroundImage: 'url(' + cityscapeEdge + ')'
    };

    return (
      <div
        className={`page-header-wrapper ${this.state.isEdge ? 'edge' : ''} ${
          pencilBanner ? 'pencil-banner-active' : ''
        } ${customPage ? 'custom-page' : ''}`}
      >
        <div className="img-wrapper">
          <div className="hero-image">
            <img src={hero} alt="" />
          </div>
          {/* <div className="cityscape">
            <img src={cityscapeFranceDesktop} alt="Cityscape desktop" />
          </div>
          <div className="cityscape-edge" style={cityscapeStyle} />
          <div className="cityscape-2">
            <img src={cityscapeFranceDesktop} alt="Cityscape desktop 2" />
          </div>
          <div className="cityscape-mobile">
            <img src={cityscapeFranceMobile} alt="Cityscape mobile" />
          </div> */}
        </div>
        <div className="page-header-content-wrapper">
          <div className="container">
            <div className="content-flex-wrapper">
              <div className={`page-header-text-wrapper ${this.state.isEdge ? 'edge' : ''}`}>
                {title ? <div className="page-header-title">{RichText.render(title)}</div> : <></>}
                {content ? <div className="page-header-content">{RichText.render(content)}</div> : <></>}
              </div>
              {/* {isEdge ? (
                <div className="edge-wheel">
                  <Wheel capabilities={capabilities} />
                </div>
              ) : (
                <Wheel capabilities={capabilities} />
              )} */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageHeader;
