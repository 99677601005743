import React, { Component } from 'react';
import { Link } from 'gatsby';
import AppContext from '../context/AppContext';
import { linkResolver } from '../utils/linkResolver';
import format from 'string-format-obj';

class TourPlaylistBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll = () => {
    this.setState({ scroll: window.scrollY });
  };

  componentDidMount() {
    let firstTourTitle = this.props.playlist[0] === undefined ? null : this.props.playlist[0].tour_title[0].text;
    if (firstTourTitle) {
      this.props.setCurrentTourTitle(firstTourTitle);      
    }
    const playlistWrapper = document.querySelector('.tour-playlist-wrapper');
    this.setState({
      height: playlistWrapper.offsetHeight
    });
    window.addEventListener('scroll', this.handleScroll);

    playlistWrapper.style.cssText = `position: fixed; bottom: 0;`;
  }

  componentDidUpdate() {
    function offset(el) {
      var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }
    let playlistWrapper = document.querySelector('.tour-playlist-wrapper');
    let footer = document.getElementsByClassName('footer')[0];
    let playlistWrapperOffset = offset(playlistWrapper);
    let playlistWrapperOffsetTop = Math.round(playlistWrapperOffset.top);
    let footerOffset = offset(footer);
    let footerOffsetTop = Math.round(footerOffset.top);
    let windowPosition = window.pageYOffset + window.innerHeight;

    let playlistSnapPosition = footerOffsetTop - this.state.height;

    if (playlistWrapperOffsetTop >= playlistSnapPosition) {
      playlistWrapper.style.cssText = 'position: absolute; bottom: 0px;';
    }

    if (windowPosition < footerOffsetTop) {
      playlistWrapper.style.position = 'fixed';
    }
  }
  componentWillUnmount() {
    // unbind the scroll listener event or react gets mad (memory leak)
    window.removeEventListener('scroll', this.handleScroll, false);
  }

  render() {
    
    let { labels, customPage, backToToursLink } = this.props;
    

    return (
      <div className="container">
        <AppContext.Consumer>
          {app => {
            if (app.playlist.length && app.playlist[0] !== undefined) {
              let firstTourMeta = app.playlist[0]._meta;

              return (
                <div className="tour-playlist-banner-container">
                  <div className="tours-added-clear-container">
                    <p>
                      <span>{`${
                        app.playlist.length <= 1
                          ? format('{count} {text} ', {
                              count: app.playlist.length,
                              text: labels.tour_added_to_playlist
                            })
                          : format('{count} {text} ', {
                              count: app.playlist.length,
                              text: labels.tours_added_to_playlist
                            })
                      }`}</span>
                    </p>
                    <button className="clear-playlist-cta" onClick={() => app.updatePlaylist([])}>
                      {labels.clear_playlist}
                    </button>
                  </div>
                  <div className="start-btn">
                    <div className="start-btn-desktop">
                      <Link to={`${linkResolver(firstTourMeta)}` + `${backToToursLink !== undefined ? '?back=/' + backToToursLink : ''}`} >
                        {app.playlist.length <= 1 ? labels.start_tour : labels.start_tours}
                      </Link>
                    </div>
                    <div className="start-btn-mobile">
                      <Link to={`${linkResolver(firstTourMeta)}` + `${backToToursLink !== undefined ? '?back=/' + backToToursLink : ''}`} >
                        {app.playlist.length <= 1 ? labels.start_tour : labels.start_tours}
                      </Link>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="tour-playlist-banner-container">
                  <div className="tours-added-clear-container">
                    <p>
                      Select from the tours above to create a playlist
                    </p>
                    <button className="clear-playlist-cta" onClick={() => app.activatePlaylistFeature()}>
                      Cancel
                    </button>
                  </div>
                </div>
              );
            }
          }}
        </AppContext.Consumer>
      </div>
    );
  }
}
export default TourPlaylistBanner;
