import { graphql } from 'gatsby';

import { TourSeoFragment } from './tourSeoFragment';
import { OrganizationTypesFragmentagment } from './organizationTypesFragment';
import { PrioritiesFragment } from './prioritiesFragment';


export const TourFragment = graphql`
  fragment TourFragment on PRISMIC_Tour {
    ...TourSeoFragment
    ...PrismicMetaFragment
    ...OrganizationTypesFragment
    ...PrioritiesFragment
    ...CapabilitiesFragment
    tour_title
    password
    popularity
    private_tour
    steps {
      ui_screen
      ui_screen_mobile
      ui_screen_video {
        ... on PRISMIC__FileLink {
          _linkType
          url
        }
      }
      step_title
      step_copy
      promo_label_1
      promo_link_1 {
        ... on PRISMIC__ExternalLink {
          url
        }
        ... on PRISMIC__FileLink {
          name
          url
          size
        }
      }
      promo_link_1_type
      promo_label_2
      promo_link_2 {
        ... on PRISMIC__ExternalLink {
          url
        }
        ... on PRISMIC__FileLink {
          name
          url
          size
        }
      }
    promo_link_2_type
    }
  }
`;