import React, { Component } from 'react';
import AppContext from '../context/AppContext';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TourTabs extends Component {
  componentDidMount() {
    let firstTab = document.getElementsByClassName('react-tabs__tab')[0];
    // focus on first tab on load
    firstTab.click();
  }

  renderTabs = () => {
    const tabs = [];
    let orgTypes = this.props.tabList;
    let tabWidth = orgTypes.length >= 5 ? '100%' : '20%';

    let alphaSortedOrgTypes = orgTypes.sort((a, b) => {
      if (a.title[0].text < b.title[0].text) return -1;
      if (a.title[0].text > b.title[0].text) return 1;
      return 0;
    });

    return (
      <AppContext.Consumer>
        {app => {
          alphaSortedOrgTypes.map((orgType, index) => {
            tabs.push(
              <Tab
                style={{ width: tabWidth }}
                selectedClassName="react-tabs__tab--selected"
                tabsrole="Tab"
                key={index}
                onClick={e => app.toggleOrgTypes(orgType._meta.id, e)}
              >
                <span className="tab-text">{orgType.title[0].text}</span>
              </Tab>
            );
          });
          return tabs;
        }}
      </AppContext.Consumer>
    );
  };

  render() {
    let tabWidth = this.props.tabList.length >= 5 ? '100%' : '20%';
    return (
      <div>
        <Tabs>
          <TabList>
            <AppContext.Consumer>
              {app => {
                return (
                  <Tab
                    style={{ width: tabWidth }}
                    selectedClassName="react-tabs__tab--selected"
                    tabsrole="Tab"
                    key={'allTab'}
                    onClick={e => app.toggleOrgTypes(null, e)}
                  >
                    <span className="tab-text">View All</span>
                  </Tab>
                );
              }}
            </AppContext.Consumer>
            {this.renderTabs()}
          </TabList>
        </Tabs>
      </div>
    );
  }
}

export default TourTabs;
