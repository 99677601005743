import { graphql } from 'gatsby';

export const TourSeoFragment = graphql`
  fragment TourSeoFragment on PRISMIC_Tour {
    meta_title
    meta_description
    canonical {
      _linkType
    }
  }
`;
