import { graphql } from 'gatsby';

export const CapabilityFragment = graphql`
  fragment CapabilityFragment on PRISMIC_Capability {
    _meta {
      id
      lang
    }
    title
    icon
    active_icon
    color
    rank
    wheel_order
  }
`;
