import { graphql } from 'gatsby';

import { OrganizationTypeFragment } from './organizationTypeFragment';

export const OrganizationTypesFragment = graphql`
  fragment OrganizationTypesFragment on PRISMIC_Tour {
    organization_types {
      organization_type {
        ... on PRISMIC_Organization_type {
          ...OrganizationTypeFragment
        }
      }
    }
  }
`;
