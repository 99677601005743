import React, { Component } from 'react';

class TourCapabilities extends Component {
  sortCapabilityByRank = newCapabilityArray => {
    let sortedCapabilites = newCapabilityArray.sort((a, b) => {
      if (a.rank === b.rank) return 0;
      if (a.rank === null) return 1;
      if (b.rank === null) return -1;
      if (a.rank < b.rank) return -1;
      if (a.rank > b.rank) return 1;
      return true;
    });
    return sortedCapabilites;
  };

  render() {
    const capabilities = this.props.capabilities;
    let showPlusCabilities = capabilities.length > 6 ? true : false;
    let newCapabilityArray = [];

    capabilities.map((capability, index) => {
      if (capability.capability !== null) {
        if (index <= 5) {
          newCapabilityArray.push(capability.capability);
        }
      }

      // if there are more than 6 capabilites, add the first 5 and the remaining length - the first 5
      if (showPlusCabilities) {
        newCapabilityArray.push(capability.capability[0]);
        newCapabilityArray.push(capability.capability[1]);
        newCapabilityArray.push(capability.capability[2]);
        newCapabilityArray.push(capability.capability[3]);
        newCapabilityArray.push(capability.capability[4]);
        newCapabilityArray.push(`+ ${capabilities.length - 5}`);
      }
      // sort capability array by rank
      newCapabilityArray = this.sortCapabilityByRank(newCapabilityArray);
      return newCapabilityArray; // removes warning
    });

    return newCapabilityArray.map((capability, index) => {
      if (capability !== null) {
        return (
          <div className="capability-icon" key={index}>
            <img
              src={capability.icon.url !== null ? capability.icon.url : ''}
              alt={`${capability.title !== null ? capability.title[0].text : ''}`}
            />
            <div className="icon-tool-tip">
              <span className="icon-tool-tip-text">
                {capability.title[0].text}
                <span className="tool-tip-arrow"></span>
              </span>
            </div>
          </div>
        );
      } else {
        return null; // removes warning
      }
    });
  }
}

export default TourCapabilities;
