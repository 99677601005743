import React from 'react';
import DataContext, { defaultState } from './DataContext';

function sortTours(tours, sortMethod) {
  switch (sortMethod) {
    case 'a_z':
      return tours.sort((a, b) => {
        if (a.tour_title[0].text < b.tour_title[0].text) return -1;
        if (a.tour_title[0].text > b.tour_title[0].text) return 1;
        return 0;
      });
    case 'newest':
      return tours.sort((a, b) => {
        if (a._meta.firstPublicationDate > b._meta.firstPublicationDate) return -1;
        if (a._meta.firstPublicationDate < b._meta.firstPublicationDate) return 1;
        return 0;
      });
    case 'most_popular':
    default:
      return tours.sort((a, b) => {
        if (a.popularity === b.popularity) return 0;
        if (a.popularity === null) return 1;
        if (b.popularity === null) return -1;
        if (a.popularity < b.popularity) return -1;
        if (a.popularity > b.popularity) return 1;
        return true;
      });
  }
}

class DataProvider extends React.Component {

  state = {
    ...defaultState
  };

  componentDidUpdate(prevProps, prevState, snapshot) {

    let { sortMethod } = this.props;

    if(prevProps.activeFilters !== this.props.activeFilters || prevProps.sortMethod !== sortMethod ){
      this.filterTours();
    }
  }

  static getDerivedStateFromProps(props, state) {
    let { filteredTours } = state;
    let { tours, priorities, capabilities, organizationTypes, homepage, lang, languages, sortMethod } = props;

    return {
      ...state,
      allTours: sortTours(tours, sortMethod),
      languages: languages,
      tours:
        filteredTours.length > 0
          ? sortTours(filteredTours, sortMethod)
          : sortTours(tours, sortMethod),
      priorities: priorities,
      capabilities: capabilities,
      organizationTypes: organizationTypes,
      homepage: homepage,
      lang: lang
    };
  }

  updateSlickSettings = settings => {
    this.setState({ settings: settings });
  };

  filterCount = filter => {
    let { activeFilters } = this.props;

    // let isActive = activeFilters.includes(filter);

    // const without = (array, filtered) => array.filter(n => n !== filtered);

    let withOnlyFilterTours = [
      ...Array.from(
        new Set([
          ...this._getCapabilitiesTours([filter]),
          ...this._getOrganizationalTours([filter]),
          ...this._getPriorityTours([filter])
        ])
      )
    ];

    // let withoutFilterTours = [
    //   ...Array.from(
    //     new Set([
    //       ...this._getCapabilitiesTours(without(activeFilters, filter)),
    //       ...this._getOrganizationalTours(without(activeFilters, filter)),
    //       ...this._getPriorityTours(without(activeFilters, filter))
    //     ])
    //   )
    // ];

    let withFilterTours = [
      ...Array.from(
        new Set([
          ...this._getCapabilitiesTours([...activeFilters, filter]),
          ...this._getOrganizationalTours([...activeFilters, filter]),
          ...this._getPriorityTours([...activeFilters, filter])
        ])
      )
    ];

    return Math.min(withOnlyFilterTours.length, withFilterTours.length);

    //return isActive ?  withFilterTours.length -  withoutFilterTours.length :  withFilterTours.length;
  };

  filteredToursContainsFilter = filter => {
    return true;
  };

  _getPriorityFilters = () => {
    let { activeFilters } = this.props;
    let { priorities } = this.state;
    let priorityArray = [];

    activeFilters.filter(function(e) {
      // let priorityArray = [];
      priorities.map((priority, index) => {
        if (priority._meta.id.indexOf(e) > -1) {
          priorityArray.push(e);
        }
        return true;
      });
      return priorityArray;
    });

    return priorityArray;
  };

  _getOrgTypeFilters = () => {
    let { activeFilters } = this.props;
    let { organizationTypes } = this.state;
    let orgTypeArray = [];

    activeFilters.filter(function(e) {
      organizationTypes.map((orgType, index) => {
        if (orgType._meta.id.indexOf(e) > -1) {
          orgTypeArray.push(e);
        }
        return true;
      });
      return true;
    });

    return orgTypeArray;
  };

  _getCapabilityFilters = () => {
    let { activeFilters } = this.props;
    let { capabilities } = this.state;
    let capabilityArray = [];

    activeFilters.filter(function(e) {
      capabilities.map((capability, index) => {
        if (capability._meta.id.indexOf(e) > -1) {
          capabilityArray.push(e);
        }
        return true;
      });
      return true;
    });

    return capabilityArray;
  };

  _getCapabilitiesTours = (filters = []) => {
    let { activeFilters } = this.props;
    let { tours } = this.state;

    filters = filters.length ? filters : activeFilters;

    let capabilityTours = tours.filter(
      t =>
        t.capabilities &&
        t.capabilities.filter(
          c => c.capability && c.capability._meta !== undefined && filters.includes(c.capability._meta.id)
        ).length > 0
    );

    return capabilityTours;
  };

  _getOrganizationalTours = (filters = []) => {
    let { activeFilters } = this.props;
    let { tours, allTours } = this.state;

    filters = filters.length ? filters : activeFilters;
    let thisSetOfTours = filters.length >= 2 ? tours : allTours;
    let organizationalTours = thisSetOfTours.filter(
      t =>
        t.organization_types &&
        t.organization_types.filter(
          c =>
            c.organization_type &&
            c.organization_type._meta !== undefined &&
            filters.includes(c.organization_type._meta.id)
        ).length > 0
    );

    return organizationalTours;
  };

  _getPriorityTours = (filters = []) => {
    let {  tours, allTours } = this.state;
    let { activeFilters } = this.props;
    filters = filters.length ? filters : activeFilters;

    let thisSetOfTours = filters.length <= 1 && activeFilters.length === 1 ? allTours : tours;

    let priorityTours = thisSetOfTours.filter(
      t =>
        t.priorities &&
        t.priorities.filter(c => c.priority && c.priority._meta !== undefined && filters.includes(c.priority._meta.id))
          .length > 0
    );

    return priorityTours;
  };

  filterTours() {

    let { allTours } = this.state;
    let { playlist, sortMethod } = this.props;

    let capabilityTours = this._getCapabilitiesTours();
    let hasCapabilityFilters = this._getCapabilityFilters().length > 0;
    let organizationalTours = this._getOrganizationalTours();
    let hasOrgTypeFilters = this._getOrgTypeFilters().length > 0;
    let priorityTours = this._getPriorityTours();
    let hasPriorityFilters = this._getPriorityFilters().length > 0;

    let filteredTours = [];

    if (!hasCapabilityFilters && !hasOrgTypeFilters && !hasPriorityFilters) {
      filteredTours = [...Array.from(new Set([...capabilityTours, ...organizationalTours, ...priorityTours]))];
    }

    if (hasCapabilityFilters && hasOrgTypeFilters && hasPriorityFilters) {
      filteredTours = [
        ...Array.from(
          new Set(capabilityTours.filter(t => organizationalTours.includes(t) && priorityTours.includes(t)))
        )
      ];
    }

    if (hasCapabilityFilters && hasOrgTypeFilters && !hasPriorityFilters) {
      filteredTours = [...Array.from(new Set(capabilityTours.filter(t => organizationalTours.includes(t))))];
    }

    if (hasCapabilityFilters && !hasOrgTypeFilters && !hasPriorityFilters) {
      filteredTours = [...Array.from(new Set(capabilityTours))];
    }

    if (!hasCapabilityFilters && hasOrgTypeFilters && !hasPriorityFilters) {
      filteredTours = [...Array.from(new Set(organizationalTours))];
    }

    if (!hasCapabilityFilters && !hasOrgTypeFilters && hasPriorityFilters) {
      filteredTours = [...Array.from(new Set(priorityTours))];
    }

    if (hasCapabilityFilters && !hasOrgTypeFilters && hasPriorityFilters) {
      filteredTours = [...Array.from(new Set(capabilityTours))];
    }

    if (!hasCapabilityFilters && hasOrgTypeFilters && hasPriorityFilters) {
      filteredTours = [...Array.from(new Set(organizationalTours))];
    }

    this.setState({
      filteredTours: sortTours(filteredTours, sortMethod),
      allTours: sortTours(allTours, sortMethod)
    });

    this.compareFilterAndPlaylist(filteredTours, playlist);
  }

  compareFilterAndPlaylist = (filteredTours, playlist) => {
    let playlistInFilter = [];
    playlist.map((list, index) => {
      filteredTours.map((tour, index) => {
        if (list._meta.id === tour._meta.id) {
          playlistInFilter.push(tour);
        }
        return true;
      });
      return true;
    });
    this.setState({
      playlist: playlistInFilter
    });
  };

  clearFilters = () => {
    var checkboxes = document.querySelectorAll('input[type="checkbox"]');

    checkboxes.forEach(function(check) {
      if (check.checked) {
        check.checked = false;
      }
    });

    this.setState({
      activeFilters: [],
      filteredTours: []
    });
  };

  setSortMethod = sortMethod => {
    let { tours } = this.state;
    let { setSortMethod } = this.props;
    let sortedTours = sortTours(tours, sortMethod);
    let sortedFilteredTours = sortTours(tours, sortMethod);

    setSortMethod(sortMethod);

    this.setState({
      tours: sortedTours,
      filteredTours: sortedFilteredTours
    });

  };

  toggleShowSort = () => {
    this.setState({
      showSort: !this.state.showSort,
      hideHeader: !this.state.hideHeader
    });
  };

  toggleShowFilters = () => {
    const { showFilters } = this.state;
    if (!showFilters) {
      this.setState({
        showFilters: true,
        hideHeader: true
      });
    } else {
      this.setState({
        showFilters: false,
        hideHeader: false
      });
    }
  };

  toggleMobileSortAndFilter = () => {
    const { showFilters, showSort } = this.state;
    if (!showFilters || !showSort) {
      this.setState({
        showFilters: true,
        showSort: true,
        hideHeader: true
      });
    } else {
      this.setState({
        showFilters: false,
        showSort: false,
        hideHeader: false
      });
    }
  };

  closeOverlay = () => {
    this.setState({
      showFilters: false,
      showSort: false,
      hideHeader: false
    });
  };

  // edge case: When a user hard refreshes on a tour, if the user clicks in the sortable playlist and clicks the tour,
  // it updates the playlist to have one array value of undefined
  // if the user clicks the logo and goes back to the home page
  // the user cannot add to their playlist
  // this will set the playlist back to an empty array
  clearUndefinedPlaylist = () => {
    if (this.state.playlist[0] === undefined) {
      this.setState({
        playlist: []
      });
    }
  };

  clearPlaylistFromTour = location => {
    if (location !== 'home') {
      this.setState({
        playlist: []
      });
    }
  };

  setCurrentTourTitle = tourName => {
    if (this.state.currentTourTitle === '' || this.state.currentTourTitle !== tourName) {
      this.setState({
        currentTourTitle: tourName
      });
    } else {
      this.setState({
        currentTourTitle: null
      });
    }
  };

  updateTourPlaylist = newTourArray => {
    this.setState({
      playlist: newTourArray
    });
  };

  getCurrentSlide = slideIndex => {
    if (this.state.currentSlide !== slideIndex) {
      this.setState({
        currentSlide: slideIndex
      });
    }
  };

  updateDefaultTimeInterval = e => {
    let newTimerInterval = parseInt(e.target.innerText);

    if (this.state.defaultTimeInterval !== newTimerInterval) {
      this.setState({
        defaultTimeInterval: newTimerInterval * 1000,
        showTimeIntervals: false,
        settings: { ...this.state.settings, autoplaySpeed: newTimerInterval * 1000 }
      });
    } else {
      return false;
    }
  };

  toggleTimerIntervals = () => {
    if (this.state.showTimeIntervals) {
      this.setState({
        showTimeIntervals: false
      });
    } else {
      this.setState({
        showTimeIntervals: true
      });
    }
  };

  togglePlayPauseButton = () => {
    if (this.state.showPlayButton) {
      this.setState({
        showPlayButton: false,
        timerInitiated: true,
        presentationMode: true
      });
    } else {
      this.setState({
        showPlayButton: true,
        timerInitiated: false,
        presentationMode: false
      });
    }
  };

  stopTourAutoPlay = () => {
    // always reset play and timer buttons when next or previous is pressed
    this.setState({
      showPlayButton: true,
      timerInitiated: false
    });
  };

  stopPresentationMode = () => {
    this.setState({
      presentationMode: false
    });
  };

  setShowPlayButton = () => {
    this.setState({
      showPlayButton: true
    });
  };

  render() {
    const { children, sortMethod } = this.props;
    const {
      tours,
      lang,
      languages,
      priorities,
      capabilities,
      organizationTypes,
      homepage,
      filteredTours
    } = this.state;

    return (
      <DataContext.Provider
        value={{
          tours: sortTours(tours, sortMethod),
          lang,
          languages,
          priorities,
          capabilities,
          organizationTypes,
          homepage,
          filterCount: this.filterCount,
          filteredTours
        }}
      >
        {children}
      </DataContext.Provider>
    );
  }
}

export { DataProvider };
