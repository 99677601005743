import { graphql } from 'gatsby';

export const OrganizationTypeFragment = graphql`
  fragment OrganizationTypeFragment on PRISMIC_Organization_type {
    _meta {
      id
      lang
    }
    title
  }
`;
