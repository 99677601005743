import { graphql } from 'gatsby';

export const CapabilitiesFragment = graphql`
  fragment CapabilitiesFragment on PRISMIC_Tour {
    capabilities {
      capability {
        ... on PRISMIC_Capability {
          ...CapabilityFragment
        }
      }
    }
  }
`;
